/* components/Sidebar.module.css */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black */
  z-index: 10001;
  /* make sure it's above other content */
}

.overlay.open {
  display: block;
}

.sidebar {
  position: fixed;
  top: 0;
  width: 430px;
  height: 100vh;
  min-height: 100%;
  z-index: 10002;
  transition: transform 0.6s ease;
  background-color: white;
}

.sidebar.left {
  left: -430px;
  transform: translateX(0);
}

.sidebar.right {
  right: -430px;
  transform: translateX(0);
}

.sidebar.bottom {
  // top: 250px;
  // height: 70vh;
  // min-height: 73%;
  // bottom: -400px;
  transform: translateY(100%);
}

.sidebar.bottom.up {
  top: 320px;
}

.sidebar.open.left {
  transform: translateX(100%);
}

.sidebar.open.right {
  transform: translateX(-100%);
}

.sidebar.open.bottom {
  transform: translateY(60em);
}

.closeButton {
  position: absolute;
  top: 17px;
  right: 18px;
  cursor: pointer;
}

// .sidebar title {
//   font-size: 19px;
//   color: #333;
//   font-family: pangrammedium;
//   letter-spacing: .01em;
// }

.slider-head {
  background-color: #f5f5f5;
  // display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 25px;

  .title {
    font-size: 18px;
    line-height: 22px;
    color: #333;
    font-family: pangrammedium;
    margin: 0px;
  }

  .link {
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    color: #e57200;
    font-size: 15px;
    line-height: 17px;
  }
}

.pad-none {
  padding: 0 !important;
}

.sidebar-details {
  padding: 20px 25px;
  overflow: auto;
  max-height: calc(100vh - 39px);

  &::-webkit-scrollbar {
    width: 0px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #dfdfdf;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #515151;
    letter-spacing: 0.01em;
    margin: 0 0 10px;
    font-family: 'pangramregular';
    letter-spacing: 0.01rem;

    a {
      color: #e27a34;
    }
  }

  strong {
    color: #222;
    font-family: 'pangrammedium';
  }
}

.sidebar.open.bottom .sidebar-details {
  max-height: 100vh;
}

.filter-sidebar-right {
  width: 24%;

  &.right {
    right: -24%;
  }

  .slider-head {
    padding: 20px 15px;
    font-family: 'pangrammedium';
    font-size: 18px;
    line-height: 22px;
    border-bottom: 1px solid #e5e5e5;
    color: #333333;
    background-color: #fff;
  }

  .sidebar-details {
    padding: 0px 20px;
  }

  .closeButton {
    left: -42px;
    top: 10px;
    right: auto;

    img {
      width: 23px;
      height: 23px;
      filter: invert(1);
    }
  }

  .sidebar-details {
    max-height: calc(100vh - 178px);
  }

  .filter-footer {
    .filterbtn {
      margin: 0 7px;
    }
  }
}

.filter-mobile-sidebar {
  .slider-head {
    padding: 16px 16px 16px 54px;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

    .title {
      font-size: 1.125rem;
      line-height: 21px;
      color: #333333;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-family: 'pangramregular';
    }

    span {
      font-size: 0.875rem;
      line-height: 19px;
      color: #707070;
      font-family: 'pangramregular';
    }
  }

  .closeButton {
    position: relative;
    background-image: url('/images/category/modal-arrow.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 16px;
    top: 14px;

    img {
      display: none;
    }
  }

  .sidebar-details {
    padding: 0px;
    max-height: calc(100vh - 42px);
  }
}

.overview-sidebar-right {
  .sidebar-details {
    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 6px;
    }
  }

  width: 30%;

  &.right {
    right: -30%;
  }

  .slider-head {
    .title {
      font-size: 19px;
      letter-spacing: 0.01em;
    }
  }
}

.saved-address-sidebar {
  .slider-head {
    padding: 16px 16px 16px 54px;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

    .title {
      font-size: 17px;
      line-height: 20px;
      color: #333333;
      display: flex;
      letter-spacing: 0.5px;
      font-weight: 500;
      font-family: 'pangrammedium';
    }
  }

  .closeButton {
    position: relative;
    background-image: url('/images/category/modal-arrow.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 16px;
    top: 14px;

    img {
      display: none;
    }
  }

  .sidebar-details {
    padding: 0px;
    background-color: #f5f5f5;
    height: 100%;
  }
}

.emi-payment-modal {
  .slider-head {
    box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 1;

    .title {
      font-size: 15px;
      display: inline;
      vertical-align: middle;
      font-weight: normal;
      color: #000;
      font-family: 'pangramregular';
    }
  }

  .closeButton {
    z-index: 2;
  }
}

.filter-search-city-sidebar {
  .sidebar-details {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .closeButton {
    top: 16px;
    right: 10px;
  }
}

.delivery-all-stores-sidebar {
  .closeButton {
    right: auto;
    left: -36px;
    top: 10px;

    img {
      filter: invert(1);
    }
  }
}

@media (max-width: 1280px) {
  .filter-sidebar-right {
    width: 28%;

    &.right {
      right: -28%;
    }
  }

  .overview-sidebar-right {
    width: 42%;

    &.right {
      right: -42%;
    }
  }
}

@media (max-width: 991px) {
  .filter-sidebar-right {
    width: 40%;

    &.right {
      right: -40%;
    }
  }
}

@media (max-width: 767px) {
  /* components/Sidebar.module.css */
  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent black */
    z-index: 1001;
    /* make sure it's above other content */
  }

  .overlay.open {
    display: block;
  }

  .sidebar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    transition: transform 0.6s ease;
    background-color: #fff;
  }
  // .sidebar.bottom{
  //   top: unset;
  //   height: auto;
  //   min-height: unset;
  //   bottom: 0;

  // }

  .sidebar.left {
    left: -100%;
    transform: translateX(0);
  }

  .sidebar.right {
    right: -100%;
    transform: translateX(0);
  }

  .closeButton {
    top: 11px;
    right: 15px;
  }

  .slider-head {
    padding: 10px 15px;

    .title {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .sidebar-details {
    padding: 15px 15px;

    p {
      font-size: 14px;
      line-height: 22px;
    }

    .sidebar {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100vh;
      z-index: 1001;
      transition: transform 0.3s ease;
      background-color: white;
    }

    .sidebar.left {
      left: -100%;
      transform: translateX(0);
    }

    .sidebar.right {
      right: -390px;
      transform: translateX(0);
    }

    .closeButton {
      top: 11px;
      right: 15px;
    }

    .slider-head {
      padding: 10px 15px;

      .title {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .sidebar-details {
      padding: 15px 15px;

      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
